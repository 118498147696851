import {RefObject, useContext, useEffect, useState} from "react";
import FormContext, {REGISTER_FORM_MODE} from "../../store/form-context";
import SecondaryButton from "../SecondaryButton";
import SnackbarContext from "../../store/snackbar-context";
import {toPng} from 'html-to-image';
import {DEFAULT_ERROR_MESSAGE, register} from "../../api/register-api";
import ReCAPTCHA from "react-google-recaptcha";
import {cleanUpHtml, isDesktop} from "../../utils/browser-utils";
import {useMediaQuery} from 'react-responsive'


type Props = {
    recaptcha: RefObject<ReCAPTCHA>;
    goBack: () => void;
    goToBeginning: () => void;
}
const ThirdStep = ({goBack, goToBeginning, recaptcha}: Props) => {
    const normalWishesFontSize = ' text-[15px] sm:text-[22.5px] md:text-3xl '
    const smallWishesFontSize = ' text-[11px] sm:text-base md:text-xl '
    const tinyWishesFontSize = ' text-[8px] sm:text-xs md:text-base '
    const {selectedCard, fullName, email, phone, productCode, formMode} = useContext(FormContext)
    const isFormMode = formMode === REGISTER_FORM_MODE;
    const [wishes, setWishes] = useState('');
    const [signature, setSignature] = useState('');
    const [sending, setSending] = useState(false);
    const [wishesFontSize, setWishesFontSize] = useState(normalWishesFontSize);
    const {setMsg} = useContext(SnackbarContext);
    const [registered, setRegistered] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [image, setImage] = useState('');
    const WISHES_MAX_LENGTH = selectedCard?.variant === 'corner' ? 263 : 263;
    const WISHES_FONT_CHANGE_LENGTH = 65;
    const WISHES_FONT_SECOND_CHANGE_LENGTH = 144;
    const SIGNATURE_MAX_LENGTH = selectedCard?.variant === 'corner' ? 17 : 19;

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)'
    })

    const isTablet = useMediaQuery({
        query: '(max-width: 900px)'
    })

    const bg = selectedCard?.color

    // selectedCard?.variant === 'background' || selectedCard?.variant === 'center' ? selectedCard.color : ' transparent ';
    const cornerStyle = selectedCard?.variant === 'corner' ? ' pl-[.85rem] sm:pl-[1.425rem] md:pl-[1.9rem] pr-[8.5rem] sm:pr-[12.75rem] md:pr-[17rem] pt-[.95rem] sm:pt-[1.425rem] md:pt-[1.9rem]' : ' px-[3.5rem] sm:px-[5.25rem] md:px-[7rem] py-[3rem] sm:py-[4.5rem] md:py-[6rem] '
    const cornerHeight = selectedCard?.variant === 'corner' ? '  h-[11rem] sm:h-[16.5rem] md:h-[22rem] ' : '  h-[9.8rem] sm:h-[14.7rem] md:h-[19.6rem] '

    const borderStyle = (sending || registered) ? '  border-none ' : ' border-2 md:border-4 border-dashed ';
    const handleWishesChange = (event: any) => {
        const value = event.currentTarget.innerText ? event.currentTarget.innerText : ''
        if (value.length < WISHES_MAX_LENGTH && !registered) {
            if (value.length > WISHES_FONT_CHANGE_LENGTH && value.length < WISHES_FONT_SECOND_CHANGE_LENGTH) {
                setWishesFontSize(smallWishesFontSize)
            } else if (value.length >= WISHES_FONT_SECOND_CHANGE_LENGTH) {
                setWishesFontSize(tinyWishesFontSize)
            } else {
                setWishesFontSize(normalWishesFontSize)
            }
            setWishes(cleanUpHtml(value))
        }
    }

    const generateImage = () => {
        let data;
        if (!sending) {
            return
        }
        const card = document.getElementById('cool-girl-card')
        if (card === null) {
            return
        }
        const minDataLength = 50000;
        let i = 0;
        const maxAttempts = 10;
        // @ts-ignore
        while ((image.length < minDataLength) && (i < maxAttempts)) {
            toPng(card, {
                cacheBust: true,
                pixelRatio: 0.7,
                width: isMobile ? 300 : isTablet ? 450 : 600,
                height: isMobile ? 200 : isTablet ? 300 : 400,
                canvasWidth: 900,
                canvasHeight: 640
            })
                .then((dataUrl) => {
                    if (dataUrl.length > minDataLength && i == maxAttempts) {
                        setImage(dataUrl)
                    }
                    data = dataUrl
                })
                .catch((err) => {
                    setMsg({msg: 'Coś poszło nie tak przy generowaniu kartki. Spróbuj ponownie później.'})
                    console.log(err)
                })
            i += 1;
        }
        return data;
    }

    const handleSend = () => {
        if (!wishes || wishes === '') {
            setMsg({msg: 'Wpisz treść życzeń'})
            return;
        }
        if (!signature || signature === '') {
            setMsg({msg: 'Podpisz się'})
            return;
        }
        // @ts-ignore
        window.dataLayer && window.dataLayer.push({
            event: "send_button",
            type: isFormMode ? "registration" : "no_registration",
        })
        setSending(true);
    }

    useEffect(() => {
        if (sending) {
            generateImage();
        }
    }, [sending])

    useEffect(() => {
        if (image && isFormMode && !registering) {
            setRegistering(true)
            const request = {
                fullName: fullName,
                email: email,
                phone: phone,
                card: image.split(',')[1],
                code: productCode,
                // token: token
            }
            register(request)
                .then((response) => {
                    if (response.status === 200) {
                        setRegistered(true)
                    } else {
                        console.error(response)
                        setMsg({msg: response.data.message})
                    }
                    setSending(false)
                    setRegistering(false)
                })
                .catch(e => {
                    setSending(false)
                    setRegistering(false)
                    console.error(e)
                    const msg = e.response?.data?.message ? e.response.data.message : DEFAULT_ERROR_MESSAGE
                    setMsg({msg})
                })
            // recaptcha.current && recaptcha.current.executeAsync()
            //     .then((token) => {
            //
            //     })
            //     .catch((exception) => {
            //         console.error('Błąd recaptchy')
            //         console.error(exception);
            //         setMsg({msg: DEFAULT_ERROR_MESSAGE})
            //     })
        }

        if (image && !isFormMode) {
            setRegistered(true)
            setSending(false)
            setRegistering(false)
        }
    }, [image])

    const handleDownload = () => {
        if (image) {
            const link = document.createElement('a')
            link.download = 'cool-girl-card.png'
            link.href = image
            link.click()
        }
    }

    const handleShare = async () => {
        if (image) {
            const blob = await (await fetch(image)).blob();
            const file = new File([blob], 'cool-girl-card.png', {type: blob.type});
            await navigator.share({
                title: 'Hello',
                text: 'Check out this image!',
                files: [file],
            })
        }
    }

    const onKeyDown = (event: any) => {
        if (registered || (event.currentTarget.innerText.length >= WISHES_MAX_LENGTH && event.keyCode != 8)) {
            if (isDesktop()) {
                event.preventDefault()
            } else {
                event.currentTarget.innerText = wishes
                let sel = window.getSelection();
                if (sel) {
                    sel.selectAllChildren(event.currentTarget);
                    sel.collapseToEnd();
                }
            }
        }
    }

    return <div
        className={'flex flex-col items-center w-full mx-auto pt-[2rem] z-50'}
    >
        <p
            style={{visibility: registered ? 'visible' : 'hidden'}}
            className={'text-base md:text-2xl text-c-primary px-[5rem] py-[.5rem] w-[30rem] sm:w-[45rem] md:w-[60rem] text-center bg-c-fifth rounded-t-[3rem]'}
        >
            GRATULACJE!
        </p>
        <div
            id={'cool-girl-card'}
            className={'relative shadow-lg w-[30rem] h-[20rem] sm:w-[45rem] sm:h-[30rem] md:w-[60rem] md:h-[40rem] ' + cornerStyle}
        >
            <img
                src={selectedCard?.largeImage}
                alt={''}
                className={'absolute top-0 left-0 w-[30rem] h-[20rem] sm:w-[45rem] sm:h-[30rem] md:w-[60rem] md:h-[40rem] w-full z-0'}
            />
            <label
                style={{visibility: !(sending || registered) ? 'visible' : 'hidden'}}
                className={'text-[8px] sm:text-xs md:text-base font-["Roboto"] text-c-primary  relative z-10'}
            >
                TWOJE ŻYCZENIA
            </label>
            <div
                className={'relative mb-[.6rem] border-c-primary flex items-center ' + borderStyle + cornerHeight}
            >
                {// @ts-ignore
                    <div placeHolder={'Tutaj wpisz treść życzeń'}
                         style={{backgroundColor: bg}}
                         contentEditable
                         onInput={handleWishesChange}
                         className={'relative overflow-hidden uppercase w-full px-[.5rem] sm:px-[.75rem] md:px-[1rem] py-[.25rem] sm:py-[0.375rem] md:py-[.5rem] text-center z-10 outline-none bg-transparent text-c-primary ' + wishesFontSize}
                         onPaste={(event) => event.preventDefault()}
                         onCopy={(event) => event.preventDefault()}
                         onKeyDown={(event) => onKeyDown(event)}
                    >
                    </div>}
            </div>
            <label
                style={{visibility: !(sending || registered) ? 'visible' : 'hidden'}}
                className={'text-[8px] sm:text-xs md:text-base font-["Roboto"] relative text-c-primary mt-[1rem] sm:mt-[1.5rem] md:mt-[2rem] relative z-10'}
            >
                TWÓJ PODPIS
            </label>
            <input
                type={'text'}
                disabled={registered}
                maxLength={SIGNATURE_MAX_LENGTH}
                style={{backgroundColor: bg}}
                className={'relative w-full font-["CanelaBold"] text-[11px] sm:text-base md:text-xl text-center z-10 outline-none bg-transparent text-c-primary ' + borderStyle}
                id={'wishes'}
                value={signature}
                onChange={(event) => setSignature(cleanUpHtml(event.target.value))}
                placeholder={" "}
            />
        </div>
        {registered && <div
            className={'relative flex flex-col w-[30rem] sm:w-[45rem] md:w-[60rem] items-center'}
        >
            <p
                className={'px-[.5rem] text-xs sm:text-sm md:text-lg bg-c-fifth rounded-b-[3rem] pt-[1.6rem] md:pt-[1rem] w-full h-[5.8rem] md:h-[6.5rem] text-c-primary text-center'}
            >
                {isFormMode ? 'TWOJE ZGŁOSZENIE ZOSTAŁO ZAREJESTROWANE' : isDesktop() ? 'POBIERZ I WYŚLIJ KARTKĘ SWOJEJ COOL GIRL' : 'POBIERZ LUB UDOSTĘPNIJ KARTKĘ SWOJEJ COOL GIRL'}
            </p>
            <div
                className={'flex w-full justify-center gap-[2rem] md:gap-[4.6rem] -translate-y-[50%]'}
            >
                <SecondaryButton
                    classes={'md:w-[30%]'}
                    variant={'white'}
                    onClick={handleDownload}
                    title={'POBIERZ'}
                />
                {!isDesktop() && <SecondaryButton
                    classes={'md:w-[30%]'}
                    onClick={handleShare}
                    variant={'white'}
                    title={'UDOSTĘPNIJ'}
                />}
            </div>
            <SecondaryButton
                classes={'md:w-[50%]  mt-[4rem] md:mt-0 mb-[4rem]'}
                onClick={goToBeginning}
                title={'NOWE ŻYCZENIA'}
            />
        </div>}
        {!registered && <div
            className={'flex flex-col my-[4rem] gap-[4rem] items-center'}
        >
            <SecondaryButton
                loading={sending}
                variant={'white'}
                onClick={handleSend}
                title={isFormMode ? 'WYŚLIJ ZGŁOSZENIE' : 'UTWÓRZ'}
            />
            <SecondaryButton
                onClick={goBack}
                title={`WRÓĆ DO KROKU ${isFormMode ? 2 : 1}`}
                leftArrows
            />
        </div>}
    </div>
}

export default ThirdStep;