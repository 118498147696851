import {ReactNode} from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import CustomSnackbar from '../components/UI/CustomSnackbar';

const MainLayout = ({children}: { children: ReactNode }) => {
    return (
        <>
            <Header/>
            <main>
                {children}
            </main>
            <CustomSnackbar/>
            <Footer/>
        </>
    );
};

export default MainLayout;
