import cardImg1a from '../../assets/cards/card1a.png';
import cardImg1b from '../../assets/cards/card1b.png';
import cardImg1c from '../../assets/cards/card1c.png';

import cardImg2a from '../../assets/cards/card2a.png';
import cardImg2b from '../../assets/cards/card2b.png';
import cardImg2c from '../../assets/cards/card2c.png';

import cardImg3a from '../../assets/cards/card3a.png';
import cardImg3b from '../../assets/cards/card3b.png';
import cardImg3c from '../../assets/cards/card3c.png';

import cardImg4a from '../../assets/cards/card4a.png';
import cardImg4b from '../../assets/cards/card4b.png';
import cardImg4c from '../../assets/cards/card4c.png';

import cardImg5a from '../../assets/cards/card5a.png';
import cardImg5b from '../../assets/cards/card5b.png';
import cardImg5c from '../../assets/cards/card5c.png';

import cardImg6a from '../../assets/cards/card6a.png';
import cardImg6b from '../../assets/cards/card6b.png';
import cardImg6c from '../../assets/cards/card6c.png';

import cardImg7a from '../../assets/cards/card7a.png';
import cardImg7b from '../../assets/cards/card7b.png';
import cardImg7c from '../../assets/cards/card7c.png';

import cardImg8a from '../../assets/cards/card8a.png';
import cardImg8b from '../../assets/cards/card8b.png';
import cardImg8c from '../../assets/cards/card8c.png';

import cardImg9a from '../../assets/cards/card9a.png';
import cardImg9b from '../../assets/cards/card9b.png';
import cardImg9c from '../../assets/cards/card9c.png';

import cardImg10a from '../../assets/cards/card10a.png';
import cardImg10b from '../../assets/cards/card10b.png';
import cardImg10c from '../../assets/cards/card10c.png';

import cardImg11a from '../../assets/cards/card11a.png';
import cardImg11b from '../../assets/cards/card11b.png';
import cardImg11c from '../../assets/cards/card11c.png';

import cardImg12a from '../../assets/cards/card12a.png';
import cardImg12b from '../../assets/cards/card12b.png';
import cardImg12c from '../../assets/cards/card12c.png';

export type Card = {
    smallImage: string;
    largeImage: string;
    color: string;
    variant: string;
}

const card1 = [
    {
        smallImage: cardImg1a,
        largeImage: cardImg1a,
        color: '#f28d19',
        variant: 'center'
    },
    {
        smallImage: cardImg1b,
        largeImage: cardImg1b,
        color: '#da1ff8',
        variant: 'center'
    },
    {
        smallImage: cardImg1c,
        largeImage: cardImg1c,
        color: '#12e338',
        variant: 'center'
    }
]

const card2 = [
    {
        smallImage: cardImg2a,
        largeImage: cardImg2a,
        color: '#f28d19',
        variant: 'center'
    },
    {
        smallImage: cardImg2b,
        largeImage: cardImg2b,
        color: '#1ff84a',
        variant: 'center'
    },
    {
        smallImage: cardImg2c,
        largeImage: cardImg2c,
        color: '#306dff',
        variant: 'center'
    }
]

const card3 = [
    {
        smallImage: cardImg3a,
        largeImage: cardImg3a,
        color: '#f28d1a',
        variant: 'background'
    },
    {
        smallImage: cardImg3b,
        largeImage: cardImg3b,
        color: '#feff0c',
        variant: 'background'
    },
    {
        smallImage: cardImg3c,
        largeImage: cardImg3c,
        color: '#0d50ff',
        variant: 'background'
    }
]

const card4 = [
    {
        smallImage: cardImg4a,
        largeImage: cardImg4a,
        color: '#f38e19',
        variant: 'corner'
    },
    {
        smallImage: cardImg4b,
        largeImage: cardImg4b,
        color: '#883bf4',
        variant: 'corner'
    },
    {
        smallImage: cardImg4c,
        largeImage: cardImg4c,
        color: '#f53cba',
        variant: 'corner'
    }
]

const card5 = [
    {
        smallImage: cardImg5a,
        largeImage: cardImg5a,
        color: '#f78f2b',
        variant: 'center'
    },
    {
        smallImage: cardImg5b,
        largeImage: cardImg5b,
        color: '#ac46f8',
        variant: 'center'
    },
    {
        smallImage: cardImg5c,
        largeImage: cardImg5c,
        color: '#24cf00',
        variant: 'center'
    }
]

const card6 = [
    {
        smallImage: cardImg6a,
        largeImage: cardImg6a,
        color: '#f28d1a',
        variant: 'center'
    },
    {
        smallImage: cardImg6b,
        largeImage: cardImg6b,
        color: '#df1af2',
        variant: 'center'
    },
    {
        smallImage: cardImg6c,
        largeImage: cardImg6c,
        color: '#f2451a',
        variant: 'center'
    }
]


const card7 = [
    {
        smallImage: cardImg7a,
        largeImage: cardImg7a,
        color: '#be136a',
        variant: 'background'
    },
    {
        smallImage: cardImg7b,
        largeImage: cardImg7b,
        color: '#e70031',
        variant: 'background'
    },
    {
        smallImage: cardImg7c,
        largeImage: cardImg7c,
        color: '#0e99c4',
        variant: 'background'
    }
]

const card8 = [
    {
        smallImage: cardImg8a,
        largeImage: cardImg8a,
        color: '#f28d19',
        variant: 'corner'
    },
    {
        smallImage: cardImg8b,
        largeImage: cardImg8b,
        color: '#0e76ff',
        variant: 'corner'
    },
    {
        smallImage: cardImg8c,
        largeImage: cardImg8c,
        color: '#4bc015',
        variant: 'corner'
    }
]

const card9 = [
    {
        smallImage: cardImg9a,
        largeImage: cardImg9a,
        color: '#eb5664',
        variant: 'background'
    },
    {
        smallImage: cardImg9b,
        largeImage: cardImg9b,
        color: '#00aa00',
        variant: 'background'
    },
    {
        smallImage: cardImg9c,
        largeImage: cardImg9c,
        color: '#b132ff',
        variant: 'background'
    }
]

const card10 = [
    {
        smallImage: cardImg10a,
        largeImage: cardImg10a,
        color: '#de0153',
        variant: 'background'
    },
    {
        smallImage: cardImg10b,
        largeImage: cardImg10b,
        color: '#aa00b0',
        variant: 'background'
    },
    {
        smallImage: cardImg10c,
        largeImage: cardImg10c,
        color: '#148700',
        variant: 'background'
    }
]

const card11 = [
    {
        smallImage: cardImg11a,
        largeImage: cardImg11a,
        color: '#f28d19',
        variant: 'center'
    },
    {
        smallImage: cardImg11b,
        largeImage: cardImg11b,
        color: '#1a7ff3',
        variant: 'center'
    },
    {
        smallImage: cardImg11c,
        largeImage: cardImg11c,
        color: '#f21adf',
        variant: 'center'
    }
]
const card12 = [
    {
        smallImage: cardImg12a,
        largeImage: cardImg12a,
        color: '#f28d19',
        variant: 'background'
    },
    {
        smallImage: cardImg12b,
        largeImage: cardImg12b,
        color: '#51be13',
        variant: 'background'
    },
    {
        smallImage: cardImg12c,
        largeImage: cardImg12c,
        color: '#2677ff',
        variant: 'background'
    }
]

export const cards = [
    card1,
    card2,
    card3,
    card4,
    card5,
    card6,
    card7,
    card8,
    card9,
    card10,
    card11,
    card12
]