import icon from '../images/facebook.png'

type Props = {
    variant?: 'small' | 'large'
}
const FacebookIcon = ({variant}: Props) => {
    const width = variant === 'small' ? 'w-[4.8rem]' : 'w-[6.3rem]'
    return <img
            src={icon}
            alt={'facebook_icon'}
        className={width}
    />
}

export default FacebookIcon;