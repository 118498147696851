type Props = {
    onClick: () => void;
    title: string;
    classes?: string;
    variant?: 'pink' | 'white';

}
const MainButton = ({title, onClick, variant = 'white', classes}: Props) => {
    const style = variant === 'pink' ? ' bg-c-fifth text-c-primary ' : ' bg-c-primary text-c-fifth '

    return <button
        className={'text-base lg:text-3xl py-[.9rem] lg:py-[1.9rem] px-[2.4rem] lg:px-[5.5rem] rounded-full ' + style + classes}
        onClick={onClick}
    >
        {title}
    </button>
}

export default MainButton;