import TextField from "../TextField";
import {useContext, useEffect, useState} from "react";
import FormContext from "../../store/form-context";
import isEmail from 'validator/lib/isEmail';
import Checkbox from "../Checkbox";
import SecondaryButton from "../SecondaryButton";
import SnackbarContext from "../../store/snackbar-context";
import {DEFAULT_ERROR_MESSAGE, verifyCode} from "../../api/register-api";
import {REGULATION_ROUTE} from "../../store/fixed-routing";

type Props = {
    goNext: () => void;
}
const FirstStep = ({goNext}: Props) => {
    const PRODUCT_CODE_ERROR_MSG = 'Niepoprawny kod z opakowania';
    const FULL_NAME_ERROR_MSG = 'Niepoprawne imię i nazwisko';
    const EMAIL_ERROR_MSG = 'Niepoprawny email';
    const PHONE_ERROR_MSG = 'Telefon powinien składać się z 9 cyfr';
    const AGREEMENTS_ERROR_MSG = 'Zaznacz wszystkie wymagane zgody';

    const fullNameRegex = /\b\w+\b.*\b\w+\b/;
    const phoneRegex = /\b\d{9}\b/;
    const digitsRegex = /^(\d+)?$/;

    const {setDetail, productCode, fullName, email, phone} = useContext(FormContext);
    const {setMsg} = useContext(SnackbarContext);
    const [validated, setValidated] = useState(false);
    const [productCodeError, setProductCodeError] = useState<string | null>(PRODUCT_CODE_ERROR_MSG);
    const [fullNameError, setFullNameError] = useState<string | null>(FULL_NAME_ERROR_MSG);
    const [emailError, setEmailError] = useState<string | null>(EMAIL_ERROR_MSG);
    const [phoneError, setPhoneError] = useState<string | null>(PHONE_ERROR_MSG);

    const [markAll, setMarkAll] = useState(false);
    const [mark18, setMark18] = useState(false);
    const [markAgreement, setMarkAgreement] = useState(false);
    const [markRules, setMarkRules] = useState(false);
    const [markNotExcluded, setMarkNotExcluded] = useState(false);
    const [markPrivacyAdministration, setMarPrivacyAdministration] = useState(false);
    // const [markPrivacy, setMarkPrivacy] = useState(false);

    useEffect(() => {
        handleProductCodeChange(productCode)
        handlePhoneChange(phone)
        handleEmailChange(email)
        handleFullNameChange(fullName)
    }, [])

    const validateForm = () => {
        setValidated(true)
        let msg
        if (!!productCodeError) {
            msg = productCodeError
        }
        if (!!fullNameError) {
            msg = fullNameError
        }
        if (!!emailError) {
            msg = emailError
        }
        if (!!phoneError) {
            msg = phoneError
        }
        if (!(mark18 && markAgreement && markRules && markNotExcluded && markPrivacyAdministration)) {
            msg = AGREEMENTS_ERROR_MSG
        }
        if (msg) {
            setMsg({msg})
            return false;
        }
        return true;
    }

    const handleProductCodeChange = (value: string) => {
        const normalizedValue = value.trim();
        if (normalizedValue.length > 0) {
            setProductCodeError(null)
        } else {
            setProductCodeError(PRODUCT_CODE_ERROR_MSG)
        }
        setDetail(value, 'productCode')
    }

    const handleFullNameChange = (value: string) => {
        const normalizedValue = value.trim();
        if (fullNameRegex.test(normalizedValue)) {
            setFullNameError(null)
        } else {
            setFullNameError(FULL_NAME_ERROR_MSG)
        }
        setDetail(value, 'fullName')
    }

    const handleEmailChange = (value: string) => {
        const normalizedValue = value.trim();
        if (isEmail(normalizedValue)) {
            setEmailError(null)
        } else {
            setEmailError(EMAIL_ERROR_MSG)
        }
        setDetail(value, 'email')
    }

    const handlePhoneChange = (value: string) => {
        const normalizedValue = value.trim();
        if (phoneRegex.test(normalizedValue)) {
            setPhoneError(null)
        } else {
            setPhoneError(PHONE_ERROR_MSG)
        }
        if (digitsRegex.test(value)) {
            setDetail(value, 'phone')
        }
    }

    const handleGoNext = () => {
        if (validateForm()) {
            verifyCode(productCode)
                .then((response) => {
                        if (response.status === 200) {
                            goNext()
                        } else {
                            setMsg({msg: response.data.message})
                        }
                    }
                ).catch(e => {
                    console.error(e)
                const msg = e.response?.data?.message ? e.response.data.message : DEFAULT_ERROR_MESSAGE
                    setMsg({msg})
            })
        }
    }

    return <div
        className={'flex flex-col items-center max-w-[48rem] mx-auto font-["Roboto"]'}
    >
        <TextField
            classes={'mt-[4rem] md:mt-[9.5rem] w-full'}
            label={'Kod z opakowania'}
            error={validated && !!productCodeError}
            name={'product-code'}
            onChange={handleProductCodeChange}
            value={productCode}
        />
        <TextField
            classes={'mt-[2.4rem] w-full'}
            label={'Imię i nazwisko'}
            error={validated && !!fullNameError}
            name={'full-name'}
            onChange={handleFullNameChange}
            value={fullName}
        />
        <TextField
            classes={'mt-[2.4rem] w-full'}
            label={'E-mail'}
            error={validated && !!emailError}
            name={'e-mail'}
            onChange={handleEmailChange}
            value={email}
        />
        <TextField
            classes={'mt-[2.4rem] w-full'}
            label={'Numer telefonu'}
            error={validated && !!phoneError}
            name={'phone'}
            onChange={handlePhoneChange}
            value={phone}
        />
        <p
            className={'mt-[2.4rem] text-base text-start w-full'}
        >
            <span
                className={'text-c-tertiary'}
            >
            *&nbsp;
            </span>
            Pola wymagane
        </p>
        <Checkbox
            name={'mark-all'}
            label={'Zaznacz wszystkie'}
            checked={markAll}
            onChange={() => setMarkAll(prev => {
                if (!prev) {
                    setMark18(true);
                    setMarkNotExcluded(true);
                    setMarPrivacyAdministration(true);
                    // setMarkPrivacy(true);
                    setMarkAgreement(true);
                    setMarkRules(true)
                }
                return !prev;
            })}
            classes={'mt-[3.4rem] pb-[3.4rem] border-b-2'}
        />
        <Checkbox
            required
            name={'mark-18'}
            label={'Oświadczam, że mam ukończone 18 lat.'}
            checked={mark18}
            onChange={() => {
                setMark18(prev => {
                    if (prev) {
                        setMarkAll(false)
                    }
                    return !prev
                })
            }}
            classes={'mt-[3.4rem]'}
        />
        <Checkbox
            required
            name={'mark-agreement'}
            label={'Oświadczam, że wyrażam zgodę na udział w Konkursie pod nazwą "#MOJA COOL GIRL".'}
            checked={markAgreement}
            onChange={() => {
                setMarkAgreement(prev => {
                    if (prev) {
                        setMarkAll(false)
                    }
                    return !prev
                })
            }}
            classes={'mt-[3.4rem]'}
        />
        <Checkbox
            required
            name={'mark-rules'}
            label={'Oświadczam, że zapoznałam/zapoznałem się z Regulaminem Konkursu pod nazwą "#MOJA COOL GIRL.".'}
            link={{url: REGULATION_ROUTE, target: '_blank'}}
            checked={markRules}
            onChange={() => {
                setMarkRules(prev => {
                    if (prev) {
                        setMarkAll(false)
                    }
                    return !prev
                })
            }}
            classes={'mt-[3.4rem]'}
        />
        <Checkbox
            required
            name={'mark-excluded'}
            label={'Oświadczam, że nie jestem osobą wyłączoną z udziału w Konkursie pod nazwą "#MOJA COOL GIRL".'}
            checked={markNotExcluded}
            onChange={() => {
                setMarkNotExcluded(prev => {
                    if (prev) {
                        setMarkAll(false)
                    }
                    return !prev
                })
            }}
            classes={'mt-[3.4rem]'}
        />
        <Checkbox
            required
            name={'mark-privacy-administration'}
            label={'Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z powszechnie obowiązującymi przepisami prawa przez Administratora danych osobowych dla celów związanych z organizacją i przeprowadzeniem Konkursu pn. „#MOJA COOL GIRL”, zgodnie z §8 Regulaminu. Moje dane osobowe udostępniam dobrowolnie oraz wiem, że przysługuje mi prawo dostępu do podanych przeze mnie danych, prawo żądania ich sprostowania, usunięcia lub ograniczenia ich przetwarzania, wniesienia sprzeciwu wobec ich przetwarzania, a także prawo żądania ich przenoszenia oraz prawo do cofnięcia zgody na ich przetwarzanie w dowolnym momencie, co pozostanie bez wpływu na zgodność z prawem ich przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Podanie danych osobowych jest dobrowolne, jednak odmowa ich podania uniemożliwia wzięcie udziału w Konkursie.'}
            checked={markPrivacyAdministration}
            onChange={() => {
                setMarPrivacyAdministration(prev => {
                    if (prev) {
                        setMarkAll(false)
                    }
                    return !prev
                })
            }}
            classes={'mt-[3.4rem]'}
        />
        {/*<Checkbox*/}
        {/*    required*/}
        {/*    name={'mark-privacy'}*/}
        {/*    label={'Oświadczam, że zapoznałem/am się z Polityką Prywatności Konkursu oraz akceptuję jej treść i zobowiązuje się w pełni do jej przetrzegania.'}*/}
        {/*    link={{url: 'https://google.com', target: '_blank'}}*/}
        {/*    checked={markPrivacy}*/}
        {/*    onChange={() => {*/}
        {/*        setMarkPrivacy(prev => {*/}
        {/*            if (prev) {*/}
        {/*                setMarkAll(false)*/}
        {/*            }*/}
        {/*            return !prev*/}
        {/*        })*/}
        {/*    }}*/}
        {/*    classes={'mt-[3.4rem]'}*/}
        {/*/>*/}
        <SecondaryButton
            onClick={handleGoNext}
            title={'KROK 2'}
            rightArrows
            classes={'my-[7.4rem]'}
        />
    </div>
}

export default FirstStep;