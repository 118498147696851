import bg from "../assets/images/bg-banner.png";
import img1 from "../assets/images/banner-1.png";
import img2 from "../assets/images/banner-2.png";
import MainButton from "./MainButton";
import {useContext} from "react";
import FormContext, {REGISTER_FORM_MODE} from "../store/form-context";
import {useNavigate} from "react-router-dom";
import {FORM_ROUTE} from "../store/fixed-routing";

const Banner = () => {
    const {setFormMode, isRegistrationEnabled} = useContext(FormContext);
    const navigate = useNavigate();

    return <section
        style={{backgroundImage: `url("${bg}")`, backgroundColor: '#ED9C00'}}
        className={'min-h-screen 2xl:h-screen bg-cover bg-start md:bg-center pt-[10rem] md:pt-[6.6rem]'}
    >
        <div
            className={'wrapper px-auto w-screen h-full min-h-screen flex flex-col md:flex-row justify-between items-center'}
        >
            <img
                src={img1}
                alt={'Jeżyki coolki'}
                className={'order-1 max-h-full w-full md:max-w-[60%] lg:max-w-[60%]'}
            />
            <div
                className={'order-2 md:max-w-[50%] relative flex flex-col justify-start mb-[5rem] md:mb-0'}
            >
                <img
                    src={img2}
                    alt={'Życzeń full dla mojej cool'}
                    className={'ml-auto order-2 md:order-1 max-w-[20rem] md:max-w-[90%]'}
                />
                <div
                    className={'flex flex-col order-1 md:order-2 items-center md:-translate-x-[50%]'}
                >
                    <MainButton
                        variant={'pink'}
                        onClick={() => {
                            setFormMode(REGISTER_FORM_MODE)
                            navigate(FORM_ROUTE)
                        }}
                        title={isRegistrationEnabled ? 'WEŹ UDZIAŁ' : 'WYŚLIJ KARTKĘ'}
                        classes={'order-3 md:order-1 xl:text-5xl px-[2.2rem] mb-[2.4rem] md:mb-0 md:py-[1rem]'}
                    />
                    <p
                        className={'order-1 md:order-2 text-lg lg:text-2xl text-center whitespace-nowrap text-c-primary text-shadow-grey md:mt-[3rem]'}
                    >
                        {isRegistrationEnabled ? 'W TERMINIE 1.02-31.03.2024 r.' : '31.03.2024 r.'}
                    </p>
                    <p
                        className={'order-2 md:order-3 text-sm lg:text-lg leading-[1.5] text-center whitespace-break-spaces md:whitespace-nowrap text-c-primary text-shadow-grey mb-[2.4rem] md:mb-[4rem] pt-[1.2rem]'}
                    >
                        {isRegistrationEnabled ? "KUP\xa0OPAKOWANIE\xa0PROMOCYJNE\nI\xa0WYŚLIJ\xa0ZGŁOSZENIE" : 'UPŁYNĄŁ TERMIN WYSYŁANIA ZGŁOSZEŃ'}
                    </p>
                </div>
            </div>
        </div>
    </section>
}

export default Banner;