import {createContext, ReactNode, ReducerState, useReducer} from 'react';
import { Card } from '../components/FormSection/Cards';

export const REGISTER_FORM_MODE = 'REGISTER_FORM_MODE';
export const SEND_ONLY_FORM_MODE = 'SEND_ONLY_FORM_MODE';

export type FORM_MODES = typeof REGISTER_FORM_MODE | typeof SEND_ONLY_FORM_MODE

type FormContextType = {
	formMode: FORM_MODES;
	productCode: string;
	fullName: string;
	email: string;
	phone: string;
	isRegistrationEnabled: boolean;
	selectedCard: Card | null;
	setDetail: (value: string, accessor: string) => void;
	selectCard: (card: Card) => void;
	setFormMode: (value:FORM_MODES) => void;
	reset: (mode: FORM_MODES) => void;
};


type ActionType = {
	type: string;
	value?: any;
};

const SET_FORM_MODE = 'setFormMode';
const SET_PRODUCT_CODE = 'SET_PRODUCT_CODE';
const SET_FULL_NAME = 'SET_FULL_NAME';
const SET_EMAIL = 'SET_EMAIL';
const SET_PHONE = 'SET_PHONE';
const SET_CARD = 'SET_CARD';
const RESET = 'RESET';

const isRegistrationEnabled = (new Date(2024, 3, 1) > new Date()) && (process.env.REACT_APP_REGISTRATION_ENABLED == 'true')

const INITIAL_FORM_STATE: FormContextType = {
	formMode: isRegistrationEnabled ? REGISTER_FORM_MODE : SEND_ONLY_FORM_MODE,
	productCode: '',
	fullName: '',
	email: '',
	phone: '',
	selectedCard: null,
	isRegistrationEnabled: isRegistrationEnabled,
	selectCard: (card: Card ) => {},
	setDetail: (value: string, accessor: string) => {},
	setFormMode: (value) => {},
	reset: (mode: FORM_MODES) => {}
};

const contentReducer = (state: FormContextType, action: ActionType): FormContextType => {
	switch (action.type) {
		case SET_FORM_MODE: {
			return {
				...state,
				formMode: isRegistrationEnabled ? action.value : SEND_ONLY_FORM_MODE,
			};
		}
		case SET_PRODUCT_CODE: {
			return {
				...state,
				productCode: action.value,
			};
		}
		case SET_FULL_NAME: {
			return {
				...state,
				fullName: action.value,
			};
		}
		case SET_EMAIL: {
			return {
				...state,
				email: action.value,
			};
		}
		case SET_PHONE: {
			return {
				...state,
				phone: action.value,
			};
		}
		case SET_CARD: {
			return {
				...state,
				selectedCard: action.value,
			};
		}
		case RESET: {
			return {
				...INITIAL_FORM_STATE,
				formMode: action.value,
			};
		}
		default:
			return state;
	}
};

export const FormContextProvider = ({ children }: { children: ReactNode }) => {
	// @ts-ignore
	const [formContext, dispatch] = useReducer(contentReducer, INITIAL_FORM_STATE as ReducerState<FormContextType>);

	const setFormMode = (value: FORM_MODES) => {
		dispatch({ type: SET_FORM_MODE, value: value });
	};

	const selectCard = (card: Card) => {
		dispatch({ type: SET_CARD, value: card });
	};

	const reset = (mode: FORM_MODES) => {
		dispatch({ type: RESET, value: mode});
	};

	const setDetail = (value: string, accessor: string) => {
		if(accessor === 'productCode') {
			dispatch({ type: SET_PRODUCT_CODE, value: value });
		}
		if(accessor === 'fullName') {
			dispatch({ type: SET_FULL_NAME, value: value });
		}
		if(accessor === 'email') {
			dispatch({ type: SET_EMAIL, value: value });
		}
		if(accessor === 'phone') {
			dispatch({ type: SET_PHONE, value: value });
		}
	};


	const contextValue: FormContextType = {
		...formContext,
		setFormMode,
		setDetail,
		selectCard,
		reset
	};
	return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

const FormContext = createContext<FormContextType>({
	...INITIAL_FORM_STATE,
});

export default FormContext;
