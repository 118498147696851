export const COMPETIION_ROUTE = '/konkurs';
export const PRIZES_ROUTE = '/konkurs#nagrody';
export const FORM_ROUTE = '/konkurs#formularz';
export const RULES_ROUTE = '/konkurs#zasady';
export const REGULATION_ROUTE = 'https://mojacoolgirl.pl/wp-content/uploads/2024/01/Regulamin-konkursu-MOJA-COOL-GIRL.pdf';
export const WINNERS_ROUTE = '/konkurs#laureaci';
export const GALLERY_ROUTE = '/konkurs#galeria';
export const CONTACT_ROUTE = '/konkurs#stopka';
export const INSTAGRAM_ROUTE = 'https://www.instagram.com/jezyki_pl/';
export const FACEBOOK_ROUTE = 'https://www.facebook.com/JezykiChwileNajezoneDoznaniami';
