type Props = {
    size: number;
}
const CircularProgress = ({size}: Props) => {
    return <svg className={'animate-spin mx-auto'} width={`${size}`} height={`${size}`} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={'stroke-[#565656]'} d="M38 20C38 29.9411 29.9411 38 20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20Z" strokeWidth="4"/>
        <mask id="path-2-inside-1_862_2766" fill="white">
            <path d="M40 20C40 23.4481 39.1085 26.8377 37.4121 29.8397C35.7157 32.8416 33.2719 35.354 30.3181 37.1329C27.3643 38.9118 24.0007 39.8968 20.5539 39.9923C17.1071 40.0878 13.6941 39.2906 10.6463 37.6779L12.5132 34.1497C14.9527 35.4404 17.6845 36.0786 20.4433 36.0022C23.2022 35.9257 25.8945 35.1373 28.2588 33.7134C30.623 32.2896 32.579 30.2786 33.9369 27.8758C35.2947 25.473 36.0083 22.7599 36.0083 20H40Z"/>
        </mask>
        <path className={'stroke-c-primary'} d="M40 20C40 23.4481 39.1085 26.8377 37.4121 29.8397C35.7157 32.8416 33.2719 35.354 30.3181 37.1329C27.3643 38.9118 24.0007 39.8968 20.5539 39.9923C17.1071 40.0878 13.6941 39.2906 10.6463 37.6779L12.5132 34.1497C14.9527 35.4404 17.6845 36.0786 20.4433 36.0022C23.2022 35.9257 25.8945 35.1373 28.2588 33.7134C30.623 32.2896 32.579 30.2786 33.9369 27.8758C35.2947 25.473 36.0083 22.7599 36.0083 20H40Z" strokeWidth="4" mask="url(#path-2-inside-1_862_2766)"/>
    </svg>

}

export default CircularProgress;