import EmptyLayout from "../layout/EmptyLayout";
import bg1 from "../assets/images/finish_background.png";
import bgSmall from "../assets/images/finish_background_small.png";
import MainButton from "../components/MainButton";
import {useNavigate} from "react-router-dom";
import {COMPETIION_ROUTE} from "../store/fixed-routing";
import Header from "../components/Header/Header";
import { useMediaQuery } from 'react-responsive'


const HomePage = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({
        query: '(max-width: 1200px)'
    })


    return <EmptyLayout>
        <Header/>
        <section
            style={{backgroundImage: isMobile ? `url("${bgSmall}")` :  `url("${bg1}")`}}
            className={'pt-[7rem] h-screen xl:pt-0 bg-cover bg-center bg-no-repeat'}
        >
            <MainButton
                onClick={() => {
                    navigate(COMPETIION_ROUTE);
                }}
                classes={'bottom-[10%] left-[50%] -translate-x-[50%] absolute z-10 text-c-tertiary shadow-2xl active:shadow-none'}
                title={'SPRAWDŹ WYNIKI'}
            />
        </section>
    </EmptyLayout>
}

export default HomePage;