import bg6 from "../../assets/images/bg6.png";
import {createRef, useContext, useEffect, useState} from "react";
import FormContext, {REGISTER_FORM_MODE} from "../../store/form-context";
import ProgressBar from "./ProgressBar";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import ReCAPTCHA from "react-google-recaptcha";
import {useNavigate} from "react-router-dom";

const FormSection = () => {
    const {formMode, reset} = useContext(FormContext);
    const isFormMode = formMode === REGISTER_FORM_MODE;
    const [step, setStep] = useState(isFormMode ? 1 : 2);
    const ref = createRef<ReCAPTCHA>();
    const navigate = useNavigate();

    useEffect(() => {
        setStep(formMode === REGISTER_FORM_MODE ? 1 : 2)
    }, [formMode])

    const goToStep = (step: number) => {
        const el = document.getElementById('formularz')
        if (el) {
            el.scrollIntoView({behavior: 'smooth'})
        }
        setStep(step)
    }

    return <section
        id={'formularz'}
        style={{backgroundImage: `url("${bg6}")`}}
        className={'max-w-screen relative min-h-screen bg-cover bg-center pt-[4rem] md:pt-[10rem]'}
    >
        <div
            className={'wrapper z-10 relative '}
        >
            <ProgressBar step={step}/>
            {/*<ReCAPTCHA*/}
            {/*    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : ''}*/}
            {/*    size={'invisible'}*/}
            {/*    ref={ref}*/}
            {/*/>*/}
            {step === 1 && <FirstStep goNext={() => goToStep(2)}/>}
            {step === 2 && <SecondStep goNext={() => goToStep(3)} goBack={() => goToStep(1)}/>}
            {step === 3 && <ThirdStep recaptcha={ref} goBack={() => goToStep(2)} goToBeginning={() => {
                reset(formMode);
                navigate("/");
            }}/>}
        </div>
    </section>
}

export default FormSection;