import bg from "../assets/images/bg-gallery.png";
import heart from "../assets/images/small_heart.png";
import cookie from "../assets/images/cookie.png";
import {useEffect, useState} from "react";
import {getGallery} from "../api/content-api";
import ArrowLeft from "../assets/icons/ArrowLeft";
import ArrowRight from "../assets/icons/ArrowRight";

const GallerySection = () => {
    const [cards, setCards] = useState([]);
    const [miniCards, setMiniCards] = useState([]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        getGallery()
            .then((response) => {
                if (response.status === 200) {
                    const cards = response.data.map((card: any) => card.large)
                    const miniCards = response.data.map((card: any) => card.medium)
                    setCards(cards)
                    setMiniCards(miniCards)
                } else {
                    console.error(response.data)
                }
            })
            .catch(exception => {
                console.error(exception?.response?.data)
            })
    }, [])

    const getNext = () => {
        if (index + 1 > cards.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }

    const getPrev = () => {
        if (index - 1 < 0) {
            setIndex(cards.length - 1)
        } else {
            setIndex(index - 1)
        }
    }

    const getValidIndex = (increment: number) => {
        if(increment > 0) {
            if(index + increment > miniCards.length -1) {
                return increment - (miniCards.length - index)
            } else {
                return index + increment
            }
        } else {
            if(index + increment < 0) {
                return miniCards.length + (index + increment)
            } else {
                return index + increment
            }
        }

    }

    return <>
        {cards && cards.length > 0 && <section
            id={'galeria'}
            style={{backgroundImage: `url("${bg}")`}}
            className={'max-w-screen relative bg-cover bg-left-top md:bg-top pt-[3.6rem] md:pt-[2rem] overflow-x-hidden'}
        >
            <img
                src={heart}
                alt={'serce'}
                className={'hidden lg:block absolute top-0 left-0 -translate-y-[50%]'}
            />
            <img
                src={cookie}
                alt={'ciastko'}
                className={'hidden lg:block absolute top-0 right-0 -translate-y-[80%]'}
            />
            <div
                className={'wrapper px-auto max-w-screen h-full flex flex-col items-center'}
            >
                <h2
                    className={'text-c-fifth text-base whitespace-nowrap lg:text-5xl bg-c-primary rounded-full w-full sm:w-auto text-center mx-auto px-[10rem] py-[.9rem] lg:py-[1.4rem]'}
                >
                    GALERIA ŻYCZEŃ
                </h2>

                <div
                    className={'relative max-w-[50rem] mt-[4rem] md:mt-[8rem] select-none'}
                >
                    <img
                        src={cards[index]}
                        alt={'Podgląd kartki'}
                    />
                    <div
                        onClick={getPrev}
                        className={'absolute top-[50%] left-0 -translate-y-[50%] bg-[#0000004D] py-[1.5rem] px-[1rem] hover:cursor-pointer hover:bg-[#00000040] active:bg-[#00000080]'}
                    >
                        <ArrowLeft/>
                    </div>
                    <div
                        onClick={getNext}
                        className={'absolute top-[50%] right-0 -translate-y-[50%] bg-[#0000004D] py-[1.5rem] px-[1rem] hover:cursor-pointer hover:bg-[#00000040] active:bg-[#00000080]'}
                    >
                        <ArrowRight/>
                    </div>

                </div>
            </div>
            <div
                className={'bg-[#0000004D] relative w-full max-w-screen py-[2rem] mt-[4rem] md:mt-[9rem] mb-[3rem]'}
            >
                <div
                    className={'wrapper flex justify-center gap-[2rem] h-[10rem]'}
                >
                    <div
                        onClick={getPrev}
                        className={'hidden sm:block py-[1.5rem] my-auto px-[1rem] hover:cursor-pointer'}
                    >
                        <ArrowLeft/>
                    </div>
                    {miniCards.length > 4 && <img
                        src={miniCards[getValidIndex(-2)]}
                        alt={'Podgląd kartki 5'}
                        className={'hidden lg:block'}
                        onClick={() =>setIndex(getValidIndex(-2))}
                    />}

                    {miniCards.length > 2 && <img
                        src={miniCards[getValidIndex(-1)]}
                        alt={'Podgląd kartki 3'}
                        onClick={() =>setIndex(getValidIndex(-1))}
                    />}
                    <img
                        className={'scale-110'}
                        src={miniCards[index]}
                        alt={'Podgląd kartki 1'}
                    />
                    {miniCards.length > 1 && <img
                        src={miniCards[getValidIndex(1)]}
                        alt={'Podgląd kartki 2'}
                        onClick={() =>setIndex(getValidIndex(1))}

                    />}
                    {miniCards.length > 3 && <img
                        src={miniCards[getValidIndex(2)]}
                        alt={'Podgląd kartki 4'}
                        className={'hidden lg:block'}
                        onClick={() =>setIndex(getValidIndex(2))}

                    />}
                    <div
                        onClick={getNext}
                        className={'hidden sm:block py-[1.5rem] my-auto px-[1rem] hover:cursor-pointer'}
                        >
                        <ArrowRight/>
                    </div>
                </div>

            </div>
        </section>}
    </>
}

export default GallerySection;