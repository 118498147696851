import {useContext, useState} from "react";
import FormContext, {REGISTER_FORM_MODE} from "../../store/form-context";
import isEmail from 'validator/lib/isEmail';
import SecondaryButton from "../SecondaryButton";
import SnackbarContext from "../../store/snackbar-context";
import {cards} from "./Cards";
import CardTile from "./CardTile";

type Props = {
    goNext: () => void;
    goBack: () => void;
}
const SecondStep = ({goBack, goNext}: Props) => {
    const {selectedCard, formMode} = useContext(FormContext);
    const isFormMode = formMode === REGISTER_FORM_MODE;
    const {setMsg} = useContext(SnackbarContext);

    const handleGoNext = () => {
        if(selectedCard) {
            goNext()
        } else {
            setMsg({msg: "Wybierz najpierw motyw i kolor"})
        }
    }

    return <div
        className={'flex flex-col items-center mx-auto'}
    >
        <div
            className={'w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-[5rem]'}
        >
            {cards.map((c) => <CardTile cards={c} goNext={goNext}/>
            )}
        </div>
        <div
            className={'flex justify-center mb-[4.2rem] gap-[6.6rem]'}
        >
            {isFormMode && <SecondaryButton
                onClick={goBack}
                title={'WRÓĆ DO KROKU 1'}
                leftArrows
            />}
            {/*<SecondaryButton*/}
            {/*    onClick={handleGoNext}*/}
            {/*    title={'KROK 3'}*/}
            {/*    rightArrows*/}
            {/*/>*/}
        </div>
    </div>
}

export default SecondStep;