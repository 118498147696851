import axios from "axios";

export const getWinners = async () => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/oo/winners' : '';
	return await axios.get(url);
};

export const getGallery = async () => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/oo/gallery' : '';
	return await axios.get(url);
};

