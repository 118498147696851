import {Link} from 'react-router-dom';

const HeaderItem = ({menu, onClick}: any) => {

    return (
        <li className='text-left md:text-center' onClick={onClick}>
            <Link
                to={menu.url}
                target={menu.newPage ? '_blank' : ''}
                className={'hover:text-c-tertiary whitespace-break-spaces text-left md:text-center relative leading-none text-xl text-c-primary uppercase'}>
                {menu.title}
            </Link>
        </li>
    )
}

export default HeaderItem
