import CircularProgress from "./UI/CircularProgress";

type Props = {
    onClick: () => void;
    rightArrows?: boolean;
    leftArrows?: boolean;
    title: string;
    variant?: 'pink' | 'white';
    classes?: string;
    loading?: boolean;
}
const SecondaryButton = ({title, onClick, rightArrows, leftArrows, loading, variant='pink', classes}: Props) => {
    const style = variant === 'pink' ? ' bg-c-fifth text-c-primary ' : ' bg-c-primary text-c-fifth '
    return <button
        style={{lineHeight: 1}}
        className={'text-base md:text-lg inline-block px-[2.4rem] flex flex-nowrap justify-center items-center font-["Plumpfull"] md:w-[40rem] md:max-w-[70%] pt-[.2rem] pb-[.3rem] rounded-full shadow-2xl active:shadow-none active:brightness-110 ' + style + classes}
        onClick={onClick}
    >
        {leftArrows && <span
            className={'text-2xl md:text-3xl mr-[1.6rem] pb-[0.3rem]'}
        >
            {"<<"}
        </span>}
        <span
            className={'text-2xl md:text-3xl pb-[0.3rem]'}
        >
            &nbsp;
        </span>
        <span
            className={'inline-block text-center align-text-top whitespace-nowrap flex items-center justify-center'}
        >
            {loading ? <CircularProgress size={20} /> : title}&nbsp;
        </span>
        {rightArrows && <span
            className={'text-2xl md:text-3xl inline-block ml-[1.6rem] pb-[0.3rem]'}
        >
            {">>"}
        </span>}
    </button>
}

export default SecondaryButton;