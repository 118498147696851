import {useState} from "react";

type Props = {
    name: string;
    type?: 'checkbox' | 'radio';
    label: string;
    checked?: boolean;
    onChange: () => void;

    required?: boolean;

    link?: {
        url: string;
        target: string;
    }
    classes?: string;
}
const Checkbox = ({name, type = 'checkbox', label, checked, onChange, link, required, classes}: Props) => {
    const [expand, setExpand] = useState(false);

    const getLabel = () => {
        if (label.length > 255 && !expand) {
            label = label.slice(0, 255) + '...'
        }
        if (link) {
            return (
                <>
                    <a className={'underline'} href={link.url} target={link.target}>{label}</a>
                    {(label.length > 255) && <span className={'underline'}
                                                   onClick={() => setExpand(!expand)}>{expand ? 'Mniej' : 'Więcej'}</span>}
                </>
            )
        } else {
            return (<>
                    {label}
                    {(label.length > 255) && <span className={'underline pl-[.5rem]'}
                                                   onClick={() => setExpand(!expand)}>{expand ? 'Mniej' : 'Więcej'}</span>}
                </>
            )
        }
    }

    const getRequired = () => {
        if (required) {
            return <span className="text-c-error"> *</span>
        }
    }

    return <label htmlFor={name} onClick={onChange}
                  className={"cursor-pointer leading-[1.2] relative pl-16 text-black text-base block w-full " + classes}>
        <input
            type={type}
            onChange={() => {
            }}
            checked={checked}
            value={type === 'radio' ? label : undefined}
            id={label}
            className="absolute opacity-0 cursor-pointer h-0 w-0 peer"
        />
        <span
            className="absolute rounded-md top-0 left-0 w-10 h-10 bg-transparent peer-checked:bg-[black] border border-black"/>
        <svg className="absolute top-3 left-2 w-10 h-10 hidden peer-checked:block" viewBox="0 0 25 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className={'stroke-[#ED9C00]'} d="M0.944336 6.33331L5.70822 9.66665L12.0554 1.33331" strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
        {getLabel()}
        {getRequired()}
    </label>
}

export default Checkbox;