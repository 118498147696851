import bg from "../assets/images/bg-box.png";
import cloud from "../assets/images/cloud.png";
import box1 from "../assets/images/box1.png";
import box2 from "../assets/images/box2.png";
import box3 from "../assets/images/box-with-code.png";
import heart from "../assets/images/heart-full.png";
import cookie from "../assets/images/coookie-xl.png";

const CloudTitle = ({classes}: any) => {
    return <div
        style={{backgroundImage: `url("${cloud}")`}}
        className={'relative px-[3.5rem] pt-[5rem] pb-[5.5rem] bg-contain bg-center bg-no-repeat ' + classes}
    >
        <p
            className={'text-c-primary text-center text-base md:text-xl whitespace-nowrap z-50'}
        >
            JEŻYKI COOLKI
        </p>
        <p
            className={'text-c-primary text-center text-sm md:text-lg whitespace-nowrap pt-[2rem]'}
        >
            W PROMOCYJNYM OPAKOWANIU
        </p>
    </div>
}

const BoxSection = () => {

    return <section
        id={'opakowania'}
        style={{backgroundImage: `url("${bg}")`}}
        className={'relative max-w-screen min-h-screen 2xl:h-screen bg-cover bg-[center_left_30%] md:bg-top'}
    >
        <img
            src={heart}
            alt={'serce pełne'}
            className={'absolute hidden md:block top-0 left-0 -translate-y-[80%]'}
        />
        <img
            src={cookie}
            alt={'ciastko duże'}
            className={'absolute hidden md:block top-0 right-0 -translate-y-[50%]'}
        />
        <div
            className={'wrapper px-auto max-w-screen h-full flex flex-col lg:flex-row items-centerflex-nowrap'}
        >
            <CloudTitle classes={'block md:hidden mt-[4rem]'}/>
            <div
                className={'flex flex-col items-center lg:items-start min-w-[70%] md:min-w-fit'}
            >
                <CloudTitle  classes={'hidden md:block'}/>
                <div
                    className={'flex flex-col md:flex-row md:py-[4rem] w-full sm:w-[80%] md:w-full justify-center'}
                >
                    <div
                        className={'self-start md:self-center md:translate-y-[2rem] max-w-[70%] md:max-w-auto'}
                    >
                        <img
                            src={box1}
                            alt={'Opakowanie w mlecznej czekoladzie'}
                            className={'translate-x-[1rem]'}
                        />
                        <p
                            className={'text-c-primary whitespace-nowrap text-base md:text-xl py-[.4rem] md:py-[1.4rem] text-center bg-c-tertiary rounded-full py-[.5rem] mx-[1rem] -translate-y-[1rem] md:-translate-y-[3rem]'}
                        >
                            JEŻYKI COOLKI MILK
                            <span
                                className={'block text-center pt-[.4rem] md:pt-[1.4rem]'}
                            >140,4g</span>
                        </p>
                    </div>
                    <div
                        className={'self-end md:self-center md:-translate-y-[2rem] max-w-[70%] md:max-w-full mt-[4rem] md:mt-0'}
                    >
                        <img
                            src={box2}
                            alt={'Opakowanie w czekoladzie'}
                            className={'translate-x-[1rem]'}
                        />
                        <p
                            className={'text-c-primary px-[2rem] text-base md:text-xl py-[.4rem] md:py-[1.4rem] whitespace-nowrap text-center bg-c-tertiary rounded-full py-[.5rem] mx-[1rem] -translate-y-[1rem] md:-translate-y-[3rem]'}
                        >
                            JEŻYKI COOLKI DARK
                            <span
                                className={'block text-center pt-[.4rem] md:pt-[1.4rem]'}
                            >140,4g</span>
                        </p>
                    </div>
                </div>

            </div>
            <div
                className={'lg:max-w-[70%] mt-[4rem] md:mt-0 mb-[7rem] lg:mb-0 flex items-center justify-center w-full'}
            >
                <img
                    src={box3}
                    alt={'Kody konkursowe znajdziesz wewnątrz opakowań'}
                />
            </div>

        </div>
    </section>
}

export default BoxSection;