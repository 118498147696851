import logo from '../assets/images/logo.png';
import {Link} from "react-router-dom";
import {FACEBOOK_ROUTE, INSTAGRAM_ROUTE, PRIZES_ROUTE, REGULATION_ROUTE, RULES_ROUTE} from '../store/fixed-routing';
import FacebookIcon from "../assets/icons/FacebookIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import { useMediaQuery } from 'react-responsive'

const Footer = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 1200px)'
    })

    return <footer
        id={'stopka'}
        className={'w-full bg-[#C3167B] px-[8.6rem] flex flex-col lg:flex-row flex-nowrap items-center lg:items-start justify-between pt-[9rem] pb-[5.6rem]'}
    >
        <div
            className={'order-1 lg:min-w-[35%]'}
        >
            <img
                src={logo}
                alt={'logo'}
                className={'lg:max-w-[33rem]'}
            />
        </div>
        <div
            className={'order-3 text-c-primary font-["Baloo"] mt-[4.5rem] lg:mt-0 min-w-[25%] w-[30rem] lg:w-auto text-lg flex flex-col items-center lg:items-start gap-[2rem]'}
        >
            <p
            >
                MASZ PYTANIA?
            </p>
            <p
            >
                SKONTAKTUJ SIĘ Z NAMI
            </p>
            <a
                href='mailto:konkurs@mojacoolgirl.pl'
            >
                KONKURS@MOJACOOLGIRL.PL
            </a>
        </div>
        <div
            className={'order-2 lg:order-3 text-c-primary mt-[4.5rem] lg:mt-0 font-["Baloo"] text-lg min-w-[25%] w-[30rem] lg:w-auto flex lg:flex-col items-center lg:items-start gap-[2rem]'}
        >
            <Link
                to={RULES_ROUTE}
                className={'hover:text-c-tertiary'}
            >
                ZASADY
            </Link>
            <Link
                className={'hover:text-c-tertiary'}
                to={PRIZES_ROUTE}
            >
                NAGRODY
            </Link>
            <Link
                className={'hover:text-c-tertiary'}
                target={'_blank'}
                to={REGULATION_ROUTE}
            >
                REGULAMIN
            </Link>
            {!isMobile && <div
                className={'flex gap-[3rem] mt-[4.5rem]'}
            >
                <Link to={FACEBOOK_ROUTE}>
                    <FacebookIcon/>
                </Link>
                <Link to={INSTAGRAM_ROUTE}>
                    <InstagramIcon/>
                </Link>
            </div>}
        </div>
        {isMobile && <div
            className={'flex order-4 w-full justify-center lg:justify-start gap-[3rem] mt-[4.5rem]'}
        >
            <Link to={FACEBOOK_ROUTE}>
                <FacebookIcon key={'mobile-facebook-icon'} variant={'small'}/>
            </Link>
            <Link to={INSTAGRAM_ROUTE}>
                <InstagramIcon variant={'small'}/>
            </Link>
        </div>}
    </footer>
}

export default Footer;