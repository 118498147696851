import DOMPurify from "dompurify";

export const isDesktop = () => {
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    return !regexp.test(details);
}

export const cleanUpHtml = (content: string, config?: DOMPurify.Config): string => {
    return `${DOMPurify.sanitize(content, {
        ...{ ADD_ATTR: ['target'] },
        ...config,
    })}`;
};