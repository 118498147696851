type Props = {
    label: string;
    name: string;
    type?: string;
    error?: boolean;
    maxLength?: number;
    min?: number;
    onChange: (value: string) => void;
    value: string | number | undefined;
    classes?: string;
}

const TextField = ({label, name, maxLength, value, min, type='text', error=false, onChange, classes}: Props) => {
    const border = error ? ' border-c-error ' : ' border-black '

    return <div className={'relative font-["Roboto"] bg-transparent ' + classes}>
        <input
            type={type}
            maxLength={maxLength}
            className={'w-full text-base p-6 rounded-2xl outline-none border-2 focus:border-c-fifth peer bg-transparent text-black' + border}
            id={name}
            value={value}
            min={min}
            onChange={(event) => onChange(event.target.value)}
            placeholder={" "}
            name={name}
        />
        <label
            className={'absolute text-xs text-c-fifth peer-focus:text-c-fifth font-medium left-7 top-1 peer-placeholder-shown:hidden peer-focus:block'}
            htmlFor={name}
        >
            {label}
        </label>
        <p className={'text-black text-base pointer-events-none absolute top-6 left-7 hidden peer-focus:hidden peer-placeholder-shown:block'}>
            {label}<span className={'text-c-error'}> *</span>
        </p>
    </div>
}

export default TextField;