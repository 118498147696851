import icon from "../images/instagram.png";

type Props = {
    variant?: 'small' | 'large'
}
const InstagramIcon = ({variant}: Props) => {
    const width = variant === 'small' ? 'w-[4.8rem]' : 'w-[6.3rem]'
    return <img
        src={icon}
        alt={'instagram_icon'}
        className={width}
    />

}

export default InstagramIcon;