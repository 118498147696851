'use client'
import {useState} from "react";
import CloseButton from "../CloseButton";
import MenuButton from "../MenuButton";
import HeaderItem from "./HeaderItem";
import {Link} from "react-router-dom";
import {FACEBOOK_ROUTE, INSTAGRAM_ROUTE} from "../../store/fixed-routing";
import FacebookIcon from "../../assets/icons/FacebookIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";


const MenuDrawer = ({menu}: any) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MenuButton onClick={() => setOpen(open => !open)} />
      <div className={`${open ? '' : 'translate-x-full'} lg:hidden fixed top-0 right-0 z-50 h-screen overflow-y-auto w-screen sm:max-w-[50%] transition-transform p-18 bg-c-fifth shadow-lg`}>
        <div className='h-full flex w-full flex-col justify-between items-center'>
          <div className='flex flex-col w-full gap-[4.8rem]  p-[2.4rem] items-start md:items-center'>
            <CloseButton onClick={() => setOpen(open => !open)} />
            <ul className='flex flex-col justify-center items-start md:items-center gap-[4.8rem] pl-[1.6rem]'>
              {menu.map((it: any, index: any) => <HeaderItem menu={it} key={it.id} onClick={(open: boolean) => setOpen(!open)} /> )}
            </ul>
              <div
                  className={'flex max-h-[4.8rem] gap-[1.6rem] mt-[4.5rem] pl-[1.6rem]'}
              >
                  <Link to={FACEBOOK_ROUTE}>
                      <FacebookIcon variant={'small'}/>
                  </Link>
                  <Link to={INSTAGRAM_ROUTE}>
                      <InstagramIcon variant={'small'}/>
                  </Link>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuDrawer;
