type ActionProps = {
  onClick: any;
}

const MenuButton = ({onClick}: ActionProps) => {
  return (
    <button className="lg:hidden space-y-2 mr-[3rem]" onClick={onClick}>
      <span className="block w-10 h-1 bg-c-primary"></span>
      <span className="block w-10 h-1 bg-c-primary"></span>
      <span className="block w-10 h-1 bg-c-primary"></span>
    </button>
  )
}

export default MenuButton
