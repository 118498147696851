import MainButton from "../MainButton";
import {useContext} from "react";
import FormContext, {REGISTER_FORM_MODE, SEND_ONLY_FORM_MODE} from "../../store/form-context";
import {useMediaQuery} from "react-responsive";

type Props = {
    step: number;
}
const ProgressBar = ({step}: Props) => {
    const {formMode} = useContext(FormContext);
    const isFormMode = formMode === REGISTER_FORM_MODE;
    const title = formMode === SEND_ONLY_FORM_MODE ? 'WYŚLIJ ŻYCZENIA' : 'WEŹ UDZIAŁ'
    const firstStepStyle = step === 1 ? ' text-c-primary bg-c-fifth' : 'text-c-fifth'
    const secondStepStyle = step === 2 ? ' text-c-primary bg-c-fifth' : 'text-c-fifth'
    const thirdStepStyle = step === 3 ? ' text-c-primary bg-c-fifth' : 'text-c-fifth'
    const isMobile = useMediaQuery({
        query: '(max-width: 900px)'
    })


    return <div
        className={'flex flex-col'}
    >
        <div
            className={'text-base lg:text-5xl text-center w-full text-c-fifth bg-c-primary py-[1rem] px-[5.5rem] rounded-full '}
        >
            {title}
        </div>
        <div
            className={'flex flex-nowrap mx-[2rem] md:mx-[3rem]'}
        >
            {isFormMode && <p
                className={"font-['Roboto'] flex-grow whitespace-break-spaces text-xs md:text-base py-[.8rem] md:py-[1.5rem] rounded-b-[1.5rem] md:rounded-b-[3rem] text-center " + firstStepStyle}
            >
                {isMobile ? '' : '1.'} {isMobile ? "Formularz\nzgłoszeniowy" : "Formularz zgłoszeniowy"}
            </p>}
            <p
                className={"font-['Roboto'] flex-grow text-xs whitespace-break-spaces  md:text-base py-[.8rem] md:py-[1.5rem] rounded-b-[1.5rem] md:rounded-b-[3rem] text-center " + secondStepStyle}
            >
                {isMobile ? '' : isFormMode ? '2.' : '1.'} {isMobile ? "Wybierz motyw\ni kolor kartki" : "Wybierz motyw i kolor kartki"}
            </p>
            <p
                className={"font-['Roboto'] flex-grow text-xs whitespace-break-spaces md:text-base py-[.8rem] md:py-[1.5rem] rounded-b-[1.5rem] md:rounded-b-[3rem] text-center " + thirdStepStyle}
            >
                {isMobile ? '' : isFormMode ? '3.' : '2.'}
                {isMobile ? isFormMode ? 'Napisz\ni zarejestruj\nżyczenia' : 'Napisz\ni wyślij\nżyczenia' : ''}
                {!isMobile ? isFormMode ? 'Napisz i zarejestruj życzenia' : 'Napisz i wyślij życzenia' : ''}
            </p>

        </div>
    </div>
}

export default ProgressBar;