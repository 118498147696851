import axios from "axios";

export const DEFAULT_ERROR_MESSAGE = "Coś poszło nie tak! Spróbuj ponownie później";

export const verifyCode = async (code: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/oo/check-code?code=${code}` : '';
	return await axios.get(url);
};

export const register = async (data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/oo/register' : '';
	return await axios.post(url, data);
};
