import bg from "../assets/images/bg-banner.png";
import info from "../assets/images/maintenance.png";

const MaintenancePage = () => {
    return <section
        style={{backgroundImage: `url("${bg}")`, backgroundColor: '#ED9C00'}}
        className={'h-screen w-screen bg-cover bg-start flex flex-col items-center justify-center'}
    >
        <img
            src={info}
            alt={'Konkurs rozpoczenie się 1 lutego 2024'}
            className={'max-w-full max-h-full'}
        />

    </section>
}

export default MaintenancePage;