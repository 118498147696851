import {useLocation} from "react-router-dom";
import {useEffect} from "react";

const ScrollToTop = (props: any) => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash === "") {
            window.scrollTo(0, 0);
        } else {
            const el = document.getElementById(location.hash.replace('#', ''))
            if (el) {
                setTimeout(() => {
                    el.scrollIntoView({behavior: 'smooth'})
                }, 500)
            }
        }
    }, [location, location.hash]);

    return <>{props.children}</>;
};

export default ScrollToTop;
