import bg from "../assets/images/bg-prizes.png";
import img1 from "../assets/images/prizes-1.png";
import img2 from "../assets/images/prizes-2.png";
import img3 from "../assets/images/prizes-3.png";
import dots3 from "../assets/images/dots3.png";
import dots4 from "../assets/images/dots4.png";

const PrizesSection = () => {
    return <section
        id={'nagrody'}
        style={{backgroundImage: `url("${bg}")`, backgroundPositionX: '90v%'}}
        className={'relative max-w-screen min-h-screen 2xl:h-screen bg-cover bg-top pt-[4rem] md:pt-[2rem]'}
    >
        <img
            src={dots3}
            alt={'kropki3'}
            className={'hidden md:block absolute top-0 left-0 -translate-y-[30%]'}
        />
        <img
            src={dots4}
            alt={'kropki4'}
            className={'hidden md:block absolute absolute top-0 right-0 -translate-y-[80%]'}
        />
        <div
            className={'wrapper px-auto max-w-screen h-full flex flex-col z-10 relative '}
        >
            <h2
                className={'text-c-fifth text-base lg:text-5xl bg-c-primary rounded-full mx-auto w-full sm:w-auto text-center px-[10rem]  py-[.9rem] lg:py-[1.4rem]'}
            >
                NAGRODY
            </h2>

            <div
                className={'flex md:flex-row flex-col relative justify-between items-center mt-[6.5rem] mb-[4rem] md:py-[10rem] md:my-[5rem] lg:my-[9rem]'}
            >
                <img
                    src={img1}
                    alt={'Nagrody główne'}
                    className={'md:max-w-[50%] h-auto md:-translate-y-[5rem]'}
                />
                <img
                    src={img2}
                    alt={'320 nagród w puli'}
                    className={'md:max-w-[20%] max-w-[40%] self-start md:absolute top-0 left-[50%] h-auto'}
                />
                <img
                    src={img3}
                    alt={'Codzienne nagrody'}
                    className={'md:max-w-[50%] h-auto md:translate-y-[5rem]'}
                />
            </div>
        </div>
    </section>
}

export default PrizesSection;