import bg from "../assets/images/bg-rules.png";
import bgMobile from "../assets/images/bg-rules-mobile.png";
import img1 from "../assets/images/step1.png";
import img2 from "../assets/images/step2.png";
import img3 from "../assets/images/step3.png";
import {Link, useNavigate} from "react-router-dom";
import {FORM_ROUTE, REGULATION_ROUTE} from "../store/fixed-routing";
import MainButton from "./MainButton";
import FormContext, {REGISTER_FORM_MODE} from "../store/form-context";
import {useContext} from "react";
import dots from "../assets/images/dots5.png";
import cookie from "../assets/images/cookie-small.png";
import { useMediaQuery } from 'react-responsive'


const RulesSection = () => {
    const {setFormMode} = useContext(FormContext);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({
        query: '(max-width: 1200px)'
    })

    return <section
        id={'zasady'}
        style={{backgroundImage: `url("${isMobile ? bgMobile : bg}")`}}
        className={'relative max-w-screen min-h-screen 2xl:h-screen bg-cover bg-top py-[4rem] md:pb-0 md:pt-[2rem]'}
    >
        <img
            src={dots}
            alt={'kropki5'}
            className={'hidden md:block absolute top-0 left-0 -translate-y-[40%]'}
        />
        <img
            src={cookie}
            alt={'ciastko małe'}
            className={'hidden md:block absolute top-0 right-0 -translate-y-[30%]'}
        />
        <div
            className={'wrapper px-auto max-w-screen h-full flex flex-col'}
        >
            <h2
                className={'text-c-fifth text-base lg:text-5xl whitespace-nowrap bg-c-primary rounded-full w-full sm:w-auto mx-auto text-center sm:px-[10rem]  py-[.9rem] lg:py-[1.4rem]'}
            >
                ZASADY KONKURSU
            </h2>
            <Link
                to={REGULATION_ROUTE}
                target={'_blank'}
                className={'mx-auto font-["Roboto"] text underline text-c-fifth text-base md:text-2xl mt-[1.6rem] md:mt-[3.4rem]'}
                >
                Regulamin konkursu
            </Link>

            <div
                className={'flex flex-col lg:flex-row relative justify-between py-[1.6rem] md:py-[3.5rem]'}
            >
                <div
                    className={'flex flex-col justify-between'}
                >
                    <img
                        src={img1}
                        alt={'Kup jeżyki'}
                        className={'grow mx-auto sm:max-w-[80%]'}
                    />
                    <p
                        className={'uppercase text-shadow-grey h-[10.8rem] [&>span]:pt-[1rem] md:whitespace-nowrap text-c-primary text-lg text-center'}
                    >
                        <span
                            className={'block'}
                        >
                            w terminie 01.02-31.03.2024
                        </span>
                        <span
                            className={'block'}
                        >
                            kup jeżyki coolki milk lub dark 140,4g
                        </span>
                        <span
                            className={'block'}
                        >
                            w opakowaniu promocyjnym
                        </span>
                        <span
                            className={'block'}
                        >
                            i zachowaj opakowanie
                        </span>
                    </p>
                </div>
                <div
                    className={'flex flex-col justify-between mt-[6.4rem] lg:mt-0'}
                >
                    <img
                        src={img2}
                        alt={'Zarejestruj unikalny kod'}
                        className={'grow mx-auto sm:max-w-[80%]'}
                    />
                    <p
                        className={'uppercase h-[10.8rem]  text-shadow-grey [&>span]:pt-[1rem] whitespace-nowrap text-c-primary text-lg text-center'}
                    >
                        <span
                            className={'block'}
                        >
                            zarejestruj
                        </span>
                        <span
                            className={'block'}
                        >
                            unikalny kod z opakowania
                        </span>
                        <span
                            className={'block'}
                        >
                            i złóż kreatywne życzenia
                        </span>
                        <span
                            className={'block'}
                        >
                            swojej cool girl
                        </span>
                    </p>
                </div>
                <div
                    className={'flex flex-col justify-between mt-[6.4rem] lg:mt-0'}
                >
                    <img
                        src={img3}
                        alt={'Graj o cool nagrody'}
                        className={'mx-auto sm:max-w-[80%]'}
                    />
                    <p
                        className={'uppercase text-shadow-grey h-[10.8rem] [&>span]:pt-[1rem] whitespace-nowrap text-c-primary text-lg text-center'}
                    >
                        <span
                            className={'block'}
                        >
                            graj o
                        </span>
                        <span
                            className={'block text-4xl'}
                        >
                            cool nagrody!
                        </span>
                    </p>
                </div>
            </div>
            <MainButton
                variant={'pink'}
                onClick={() => {
                    setFormMode(REGISTER_FORM_MODE)
                    navigate(FORM_ROUTE)
                }}
                title={'WEŹ UDZIAŁ'}
                classes={'text-5xl px-[5.7rem] py-[1rem] mx-auto'}
            />
            <p
                className={'text-c-fifth mt-[3rem] mb-[2.7rem] text-center text-base mx-auto font-["Roboto"]'}
            >
                Ważne! Zachowaj opakowanie - w razie wygranej będzie niezbędne w celu wydania nagrody.
            </p>
        </div>
    </section>
}

export default RulesSection;