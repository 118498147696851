import bg from "../assets/images/bg-winners.png";
import dots1 from "../assets/images/dots1.png";
import dots2 from "../assets/images/dots2.png";
import ArrowLeft from "../assets/icons/ArrowLeft";
import ArrowRight from "../assets/icons/ArrowRight";
import {useEffect, useState} from "react";
import {getWinners} from "../api/content-api";

const WinnersSection = () => {
    // MM.DD.YYYY
    const FINISH_DATE = new Date('04/08/2024');
    const todayString = (new Date()).toLocaleDateString('pl-PL', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })
    const isFinished = (new Date()).getTime() >= FINISH_DATE.getTime()
    const [dailyWinners, setDailyWinners] = useState();
    const [days, setDays] = useState<string[]>([]);
    const [dayIndex, setDayIndex] = useState(0);
    const [mainWinners, setMainWinners] = useState();

    useEffect(() => {
        getWinners()
            .then((response) => {
                if (response.status === 200) {
                    setDailyWinners(response.data.dailyStats)
                    setDays(Object.keys(response.data.dailyStats))
                    setMainWinners(response.data.mainWinners)
                } else {
                    console.error(response.data)
                }
            })
            .catch(exception => {
                console.error(exception?.response?.data)
            })
    }, [])

    const getNextDay = () => {
        if (dayIndex + 1 > days.length - 1) {
            setDayIndex(0)
        } else {
            setDayIndex(dayIndex + 1)
        }
    }

    const goPrevDay = () => {
        if (dayIndex - 1 < 0) {
            setDayIndex(days.length - 1)
        } else {
            setDayIndex(dayIndex - 1)
        }
    }

    return <section
        id={'laureaci'}
        style={{backgroundImage: `url("${bg}")`}}
        className={'max-w-screen bg-cover bg-[center_left_10%] md:bg-top pt-[2rem] relative'}
    >
        <img
            src={dots1}
            alt={'kropki'}
            className={'hidden md:block absolute top-0 left-0 -translate-y-[80%]'}
        />
        <img
            src={dots2}
            alt={'kropki2'}
            className={'hidden md:block absolute top-0 right-0 -translate-y-[50%]'}
        />
        <div
            className={'wrapper px-auto max-w-screen h-full flex flex-col'}
        >
            <h2
                className={'text-c-fifth text-base lg:text-5xl bg-c-primary rounded-full w-full sm:w-auto text-center mx-auto px-[10rem] py-[.9rem] lg:py-[1.4rem]'}
            >
                LAUREACI
            </h2>

            <div
                className={'flex flex-col md:flex-row relative justify-between max-w-full pt-[4rem] lg:pt-[10rem] pb-[4rem] md:pb-[15rem] gap-[2.4rem]'}
            >
                <div
                    className={'grow flex flex-col md:block md:max-w-[50%]'}
                >
                    <h3
                        className={'text-base lg:text-2xl bg-c-fifth mx-auto text-c-primary rounded-full text-center py-[1rem] px-[2.4rem] lg:py-[1.4rem] md:w-[36rem] mb-[1.6rem] md:mb-[5rem]'}
                    >
                        NAGRODY CODZIENNE
                    </h3>
                    <div
                        className={'bg-c-primary rounded-[1.6rem] p-[3rem] h-[33.1rem] flex flex-col'}
                    >
                        <div
                            className={'flex justify-center'}
                        >
                            <div
                                className={'bg-c-fifth rounded-[1rem] md:rounded-[1.6rem] pl-[1rem] md:pl-[2rem] pr-[1rem] md:pr-[2.5rem] flex items-center hover:cursor-pointer active:brightness-110'}
                                onClick={() => goPrevDay()}
                            >
                                <ArrowLeft/>
                            </div>
                            <p
                                className={'bg-c-fifth text-lg lgv:text-2xl py-[1rem] w-[23rem] text-c-primary text-center rounded-[1rem] md:rounded-[1.6rem] mx-[2rem]'}
                            >
                                {days ? days[dayIndex] ? days[dayIndex] : todayString : todayString}
                            </p>
                            <div
                                className={'bg-c-fifth rounded-[1rem] md:rounded-[1.6rem]  pr-[1rem] md:pr-[2rem] pl-[1rem] md:pl-[2.5rem] flex items-center hover:cursor-pointer active:brightness-110'}
                                onClick={() => getNextDay()}
                            >
                                <ArrowRight/>
                            </div>
                        </div>
                        <ul
                            className={'font-["Roboto"] text-base md:text-lg text-c-fifth [&>li]:pt-[1.5rem] pb-[1.5rem] border-b-2 border-t-2 border-c-fifth mt-[3rem] h-full'}
                        >
                            {
                                // @ts-ignore
                                dailyWinners && dailyWinners[days[dayIndex]] && dailyWinners[days[dayIndex]].map((w: any, i: number) => (
                                <li key={'winnner-' + i}>{`${w.fullName}. nr.tel ${w.phone} xxx xxx - `}<strong>100zł</strong></li>
                            ))}
                        </ul>

                    </div>
                </div>
                <div
                    className={'grow flex flex-col md:block max-w-full md:max-w-[50%] z-10 mt-[4.8rem] md:mt-0'}
                >
                    <h3
                        className={'text-base lg:text-2xl bg-c-fifth mx-auto text-c-primary rounded-full text-center py-[1rem] px-[2.4rem] lg:py-[1.4rem] md:w-[36rem] mb-[1.6rem] md:mb-[5rem]'}
                    >
                        NAGRODY GŁÓWNE
                    </h3>
                    <div
                        className={'bg-c-primary rounded-[1.6rem] p-[3rem] pt-0 h-[33.1rem] flex flex-col'}
                    >
                        <p
                            className={'flex justify-center text-center text-base lg:text-xl text-c-fifth pt-[1.6rem] sm:pt-[5.3rem]'}
                        >
                            {isFinished ? "LISTA LAUREATÓW:" : "LISTA LAUREATÓW OGŁOSZONA BĘDZIE:"}
                        </p>
                        {!isFinished &&
                            <div
                                className={'flex flex-col justify-center items-center pb-[1.5rem] border-b-2 border-t-2 border-c-fifth mt-[2.4rem] md:mt-[3rem] h-full'}
                            >
                                <p
                                    className={'bg-c-fifth text-xl lg:text-2xl py-[1rem] w-[23rem] text-c-primary text-center rounded-[1rem] md:rounded-[1.6rem] mx-[2rem]'}
                                >
                                    {FINISH_DATE.toLocaleDateString('pl-PL', {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                    })}
                                </p>
                                <p
                                    className={'text-base lg:text-2xl w-[23rem] text-c-fifth text-center mx-[2rem] mt-[2.2rem]'}
                                >
                                    POWODZENIA!
                                </p>
                            </div>
                        }
                        {isFinished &&
                            <ul
                                className={'font-["Roboto"] overflow-y-scroll text-base md:text-lg text-c-fifth [&>li]:pt-[1.5rem] pb-[1.5rem] border-b-2 border-t-2 border-c-fifth mt-[3rem] h-full'}
                            >
                                {
                                    // @ts-ignore
                                    mainWinners && mainWinners.map((w: any) => (
                                        <li>{`${w.fullName}. nr.tel ${w.phone} xxx xxx - `}<strong>1000zł</strong></li>
                                    ))}
                            </ul>}
                    </div>
                </div>
            </div>

        </div>
    </section>
}

export default WinnersSection;