import MainLayout from "../layout/MainLayout";
import FormSection from "../components/FormSection/FormSection";
import Banner from "../components/Banner";
import PrizesSection from "../components/PrizesSection";
import BoxSection from "../components/BoxSection";
import RulesSection from "../components/RulesSection";
import WinnersSection from "../components/WinnersSection";
import GallerySection from "../components/GallerySection";

type ParagraphType = {
    children: any;
    classes?: string;
}
const Paragraph = (props: ParagraphType) => {
    return <p
        className={'text-xl text-center text-c-primary text-shadow ' + props.classes}
    >
        {props.children}
    </p>
}
const MainPage = () => {
    return <MainLayout>
        <Banner/>
        <PrizesSection/>
        <BoxSection/>
        <RulesSection/>
        <FormSection/>
        <WinnersSection/>
        <GallerySection/>
    </MainLayout>
}

export default MainPage;