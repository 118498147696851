import { useContext } from 'react';
import SnackbarContext from '../../store/snackbar-context';

const CustomSnackbar = () => {
	const {msg, severity, show} = useContext(SnackbarContext);

	const getColor = (severity: string) => {
		switch (severity) {
			case 'error':
				return 'bg-c-error';
			case 'success':
				return 'bg-c-success';
			default:
				return 'bg-c-fifth'
		}
	}

	return <>
		{show && <div className={'mx-[1rem] px-10 py-6 z-50 text-xl md:text-2xl text-center text-c-secondary fixed bottom-10 right-0 rounded-full ' + getColor(severity)}>
			<p>{msg}</p>
		</div>}
	</>
};

export default CustomSnackbar;
