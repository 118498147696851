import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SnackbarContextProvider} from "./store/snackbar-context";
import {BrowserRouter, useLocation, useNavigationType} from 'react-router-dom';
import {FormContextProvider} from "./store/form-context";
import TagManager from 'react-gtm-module';
import * as Sentry from "@sentry/react";
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

if(process.env.REACT_APP_ENV === 'production') {
    const tagManagerArgs = {
        gtmId: 'GTM-TK8CQPTX',
        events: {
            eventName: "send_button",
        }
    }
    TagManager.initialize(tagManagerArgs)

    Sentry.init({
        dsn: "https://7c9e639e1e8f09853fdafeaa5c618b74@o4506722024357888.ingest.sentry.io/4506722031304704",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/mojacoolgirl\.pl/],
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    datadogLogs.init({
        clientToken: 'pub286b4ae639e0b7d60b87a2a229baddca',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sessionSampleRate: 100,
    })

    datadogRum.init({
        applicationId: '6ae5b9bb-9568-4baa-bf24-7a16e1b3100b',
        clientToken: 'pub286b4ae639e0b7d60b87a2a229baddca',
        site: 'datadoghq.eu',
        service: 'mojacoolgirl',
        env: 'production',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <SnackbarContextProvider>
                <FormContextProvider>
                    <App/>
                </FormContextProvider>
            </SnackbarContextProvider>
        </BrowserRouter>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
