import {Link, useLocation} from "react-router-dom";
import HeaderItem from "./HeaderItem";
import HomeIcon from "../../assets/icons/HomeIcon";
import MenuDrawer from "./MenuDrawer";
import {
    CONTACT_ROUTE,
    GALLERY_ROUTE,
    PRIZES_ROUTE,
    REGULATION_ROUTE,
    RULES_ROUTE,
    WINNERS_ROUTE
} from "../../store/fixed-routing";

const menu = [
    {
        id: 'nagrody',
        url: PRIZES_ROUTE,
        newPage: false,
        title: 'NAGRODY'
    },
    {
        id: 'zasady',
        url: RULES_ROUTE,
        newPage: false,
        title: 'ZASADY'
    },
    {
        id: 'regulamin',
        url: REGULATION_ROUTE,
        newPage: true,
        title: 'REGULAMIN'
    },
    {
        id: 'laureaci',
        url: WINNERS_ROUTE,
        newPage: false,
        title: 'LAUREACI'
    },
    {
        id: 'galeria',
        url: GALLERY_ROUTE,
        newPage: false,
        title: 'GALERIA\nZGŁOSZEŃ'
    },
    {
        id: 'stopka',
        url: CONTACT_ROUTE,
        newPage: false,
        title: 'KONTAKT'
    },
]

const Header = () => {
    const location = useLocation();
    return (
        <header className={'fixed top-0 left-0 py-[1rem] w-full bg-c-fifth z-50 duration-700'}>
            <nav className='flex justify-between items-center'>
                <Link
                    className={'pb-[.5rem] ml-[3rem]'}
                    to={location.pathname === '/' ? '/konkurs' : '/'}
                >
                    <HomeIcon/>
                </Link>
                <ul className='wrapper xs:hidden lg:flex w-full flex-row justify-between items-center'>
                    {menu.map((it, index) => <HeaderItem menu={it} key={it.id}/>)}
                </ul>
                <MenuDrawer menu={menu}/>
            </nav>
        </header>
    )
}

export default Header
