import React from 'react';
import ScrollToTop from "./pages/ScrollToTop";
import {Helmet} from 'react-helmet';
import {Route, Routes} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import {COMPETIION_ROUTE} from "./store/fixed-routing";
import MainPage from './pages/MainPage';
import MaintenancePage from './pages/MaintenancePage';
import FinishPage from "./pages/FinishPage";

function App() {
    const START_DATE = new Date('02/01/2024');
    const isStarted = ((new Date()).getTime() >= START_DATE.getTime()) || process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_MAINTENANCE === "true"

    return (
        <ScrollToTop>
            <Helmet>
                <title>{process.env.REACT_APP_NAME}</title>
                {process.env.REACT_APP_ENV !== 'production' &&
                    <meta name="robots" content="noindex, nofollow"/>
                }
                <link
                    rel='icon'
                    href={process.env.PUBLIC_URL + '/favicon.ico'}
                />
                <link
                    rel='manifest'
                    href={process.env.PUBLIC_URL + '/manifest.json'}
                />
            </Helmet>
            <Routes>
                <Route
                    key={'home'}
                    path={'/'}
                    element={isStarted ? <FinishPage/> : <MaintenancePage/>}
                />
                <Route
                    key={'competition'}
                    path={COMPETIION_ROUTE}
                    element={<MainPage/>}
                />
                <Route
                    key={'default'}
                    path={'*'}
                    element={<ErrorPage/>}
                />
            </Routes>
        </ScrollToTop>
    );
}

export default App;
