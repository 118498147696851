import {useContext, useState} from "react";
import {Card} from "./Cards";
import FormContext from "../../store/form-context";

type Props = {
    cards: Card[],
    goNext?: () => void;
}
const CardTile = ({cards, goNext}: Props) => {
    const [index, setIndex] = useState(0);
    const {selectedCard, selectCard} = useContext(FormContext);
    const isSelected = cards.find((c) => c === selectedCard )

    const bg = isSelected && false ? ' bg-[#FAE1B2] ' : ''
    const vis = isSelected && false ? ' visible ' : ' invisible '

    function darkenColor(hex: string, factor: number) {
        factor = Math.min(1, Math.max(0, factor));

        let r = parseInt(hex.slice(1, 3), 16);
        let g = parseInt(hex.slice(3, 5), 16);
        let b = parseInt(hex.slice(5, 7), 16);

        r = Math.round(r * (1 - factor));
        g = Math.round(g * (1 - factor));
        b = Math.round(b * (1 - factor));

        return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
    }

    const selection = cards.map((c, i) => {
            const style = i === index ? ' scale-[120%] shadow-md ' : ''
            return <div
                key={'selection-' + i}
                style={{backgroundColor: c.color, borderColor: i === index ? '#fff' : darkenColor(c.color, 0.3)}}
                className={'h-[3rem] w-[5rem] border-[3px] hover:scale-[120%] ' + style}
                onClick={() => setIndex(i)}
            />
        })

    const handleSelection = () => {
        selectCard(cards[index])
        goNext && goNext()
    }

    return <div
        className={'flex flex-col rounded-lg p-[1rem] hover:bg-[#FAE1B2] gap-[1.5rem] group ' + bg}
    >
        <img src={cards[index].smallImage} alt={'thumbnail'} className={'shadow-lg'}/>
        <div
            className={'flex justify-around group-hover:visible ' + vis}
        >
            {selection}
        </div>

        <button
            className={'invisible border-2 mb-[1rem] group-hover:visible rounded-full max-w-[50%] py-[.5rem] text-base border-c-fifth text-c-fifth w-full mx-auto hover:bg-c-fifth hover:text-c-primary active:brightness-110'}
            onClick={handleSelection}
        >
            WYBIERZ
        </button>
    </div>
}

export default CardTile;